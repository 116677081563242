import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/Header.css';
import logo from '../image/secur.webp'; // Make sure to replace with the actual path to your logo

function Header() {
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(false);
  const menuRef = useRef();

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <nav className={`nav ${menuActive ? 'scrolled' : ''}`} ref={menuRef}>
      <div className="logo-container" onClick={() => navigate('/')}>
        <img src={logo} alt="Svensk Säkerhetsgrupp Logo" className="logo" />
      </div>
      <ul className={`nav-menu ${menuActive ? 'show' : ''}`}>
        <li><button onClick={() => { navigate('/'); toggleMenu(); }}>Hem</button></li>
        <li><button onClick={() => { navigate('/about'); toggleMenu(); }}>Om oss</button></li>
        <li><button onClick={() => { navigate('/contact'); toggleMenu(); }}>Kontakta oss</button></li>
      </ul>
      <div className={`hamburger ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </nav>
  );
}

export default Header;