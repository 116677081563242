import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../Styles/Contact.css';
import Header from './Header';

function Contact() {
  const [formData, setFormData] = useState({
    email: '',
    phone: '',
    subject: 'Offert',  // Default value for the select
    message: ''
  });

  useEffect(() => {
    console.log('Service ID:', process.env.REACT_APP_EMAILJS_SERVICE_ID);
    console.log('Template ID:', process.env.REACT_APP_EMAILJS_TEMPLATE_ID);
    console.log('User ID:', process.env.REACT_APP_EMAILJS_USER_ID);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const templateParams = {
      user_email: formData.email,
      phone: formData.phone,
      subject: formData.subject,
      message: formData.message,
    };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      templateParams,
      process.env.REACT_APP_EMAILJS_USER_ID
    )
    .then((result) => {
        console.log('Email successfully sent!', result.text);
        alert('Form submitted successfully!');
    })
    .catch((error) => {
        console.error('Error sending email:', error.text);
        alert('Failed to send email. Please try again later.');
    });
  };

  return (
    <>
      <Header />
      <main className="contact-container">
        <h1>Kontakta Oss</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            aria-required="true"
            aria-label="Enter your email"
          />
          
          <label htmlFor="phone">Telefonnummer</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            aria-required="true"
            aria-label="Enter your phone number"
          />
          
          <label htmlFor="subject">Ämne</label>
          <select
            id="subject"
            name="subject"
            value={formData.subject}
            onChange={handleChange}
            required
            aria-required="true"
            aria-label="Select a subject"
          >
            <option value="Offert">Offert</option>
            <option value="Önskar bli uppringd">Önskar bli uppringd</option>
            <option value="Övrigt">Övrigt</option>
          </select>
          
          <label htmlFor="message">Meddelande</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            aria-required="true"
            aria-label="Enter your message"
          ></textarea>
          
          <button type="submit" aria-label="Send message">Skicka Meddelande</button>
        </form>
      </main>
    </>
  );
}

export default Contact;
