import React from 'react';
import '../Styles/Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <p>&copy; {new Date().getFullYear()} Green multikonsult och säkerhet GMS AB. All Rights Reserved.</p>
        <p>
          Contact us at: 
          <a href="mailto:info@gmksab.se" aria-label="Email us">
            <i className="fas fa-envelope" aria-hidden="true"></i>
            <span className="sr-only">Email us</span>
          </a> | 
          Phone: 
          <a href="tel:0101475070" aria-label="Call us">
            <i className="fas fa-phone-alt" aria-hidden="true"></i>
            <span className="sr-only">Call us</span>
          </a>
        </p>
        <p>
          Follow us: 
          <a href="https://www.facebook.com/people/Green-Security-Group-AB/100088204808179/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Facebook">
            <i className="fab fa-facebook-f" aria-hidden="true"></i>
            <span className="sr-only">Facebook</span>
          </a> 
          <a href="https://www.instagram.com/greensecuritygroup/" target="_blank" rel="noopener noreferrer" aria-label="Follow us on Instagram">
            <i className="fab fa-instagram" aria-hidden="true"></i>
            <span className="sr-only">Instagram</span>
          </a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
