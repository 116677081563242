import React, { useState } from 'react';
import Header from './Header';
import Modal from './Modal';
import '../Styles/AboutUs.css';
import sectionsData from '../data/sections.json';
import trygghetsvardar from '../image/trygghetsvardar.jpg';
import secur from '../image/secur.webp';
import security from '../image/security.jpg';
import vaktare from '../image/vaktare.jpg';
import wallpaper from '../image/wallpaper.jpg';

const imageMap = {
  'trygghetsvardar.jpg': trygghetsvardar,
  'secur.webp': secur,
  'security.jpg': security,
  'vaktare.jpg': vaktare,
  'wallpaper.jpg': wallpaper
};

function AboutUs() {
  const [expandedSection, setExpandedSection] = useState(null);

  const handleSectionClick = (section) => {
    if (expandedSection === section.id) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section.id);
    }
  };

  return (
    <>
      <Header />
      <main className="about-container">
        <h1>Om Oss</h1>
        {sectionsData.map((section) => (
          <section
            key={section.id}
            className={`section-container`}
            onClick={() => handleSectionClick(section)}
          >
            <div
              className="section-background"
              style={{ backgroundImage: `url(${imageMap[section.backgroundImage]})` }}
              role="img"
              aria-label={section.imageAlt} // Assuming section.imageAlt is provided in sectionsData
            ></div>
            <div className="about-content">
              <h2>{section.title}</h2>
              <button 
                className="read-more-button" 
                onClick={(e) => { e.stopPropagation(); handleSectionClick(section); }}
                aria-expanded={expandedSection === section.id}
                aria-controls={`section-content-${section.id}`}
                aria-label={`Read more about ${section.title}`}
              >
                Läs mer
              </button>
            </div>
          </section>
        ))}

        {expandedSection && (
          <Modal
            show={expandedSection !== null}
            onClose={() => setExpandedSection(null)}
            title={sectionsData.find(section => section.id === expandedSection).title}
            description={sectionsData.find(section => section.id === expandedSection).description}
            image={imageMap[sectionsData.find(section => section.id === expandedSection).backgroundImage]}
          />
        )}
      </main>
    </>
  );
}

export default AboutUs;
