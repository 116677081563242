import React, { useState, useEffect } from 'react';
import '../Styles/Home.css';
import Modal from '../Components/Modal';
import sectionhomeData from '../data/sectionhome.json';

function Home() {
  const [expandedService, setExpandedService] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    setServices(sectionhomeData.map(service => ({
      ...service,
      image: require(`../image/${service.image}`)
    })));
  }, []);

  return (
    <div className="container">
      <header className="main-header">
        <div className="main-header-content">
          <h1>Välkommen till Green multikonsult och säkerhet AB</h1>
          <p>Din säkerhet, vårt ansvar</p>
          <h1>GMS</h1>
        </div>
      </header>
      <section className="section" id="about">
        <h2>Vem är vi?</h2>
        <p>
          Vi är en ledande säkerhetsgrupp i Sverige, dedikerade till att skydda dina tillgångar och säkerställa din trygghet. Green Multikonsult och Säkerhet GMS AB är ett socialt företag som erbjuder högkvalificerade säkerhetstjänster, skräddarsydda efter era behov. Vårt mål är att skapa en säker och välkomnande miljö, oavsett var ni befinner er. Med vår expertis och engagemang kan ni känna er trygga och säkra. Lita på oss för att leverera den bästa säkerhetslösningen för er och er verksamhet.
        </p>
      </section>

      <section className="section" id="services">
        <h2>Våra Tjänster</h2>
        <div className="services">
          {services.map((service, index) => (
            <div className="service" key={index} onClick={() => setExpandedService(service)}>
              <img src={service.image} alt={service.title} className="service-image" />
              <h3>{service.title}</h3>
              <p>
                {`${service.description.substring(0, 100)}...`}
              </p>
              <button onClick={(e) => { e.stopPropagation(); setExpandedService(service); }}>
                Läs mer
              </button>
            </div>
          ))}
        </div>
      </section>

      <section className="section testimonial">
        <h2>Vad våra kunder säger</h2>
        <p>
          "GMS erbjuder oöverträffad service och säkerhet."
          - Mekonomen bilverkstad Västerslett
        </p>
      </section>
      <Modal
        show={expandedService !== null}
        onClose={() => setExpandedService(null)}
        title={expandedService?.title}
        description={expandedService?.description}
        image={expandedService?.image}
      />
    </div>
  );
}

export default Home;
