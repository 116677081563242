import React, { useEffect, useRef } from 'react';
import '../Styles/Modal.css';

const Modal = ({ show, onClose, title, description, image }) => {
  const modalRef = useRef(null);
  const closeButtonRef = useRef(null);

  useEffect(() => {
    if (show) {
      modalRef.current.focus();
    }
  }, [show]);

  if (!show) return null;

  const handleKeyDown = (e) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  return (
    <div
      className="modal-overlay"
      onClick={onClose}
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      ref={modalRef}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button
          className="modal-close"
          onClick={onClose}
          aria-label="Close modal"
          ref={closeButtonRef}
        >
          X
        </button>
        <img src={image} alt={title} className="modal-image" />
        <h2 id="modal-title">{title}</h2>
        <p id="modal-description">{description}</p>
      </div>
    </div>
  );
};

export default Modal;
